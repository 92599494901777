<template>
  <!-- eslint-disable vue/no-multiple-template-root -->
  <Html :lang="head.htmlAttrs?.lang">
    <Head>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :content="meta.content" :property="meta.property" />
      </template>

      <Meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
    </Head>
  </Html>

  <NuxtPwaManifest />

  <v-app>
    <v-layout>
      <v-main scrollable name="main-content">
        <slot />

        <snackbar-toaster :snackbars="snackbars" />
      </v-main>

      <v-dialog :model-value="cookiePreferences.length === 0" persistent width="600">
        <cookie-message @accept="(preferences) => setCookiePreferences(preferences)" />
      </v-dialog>
    </v-layout>
  </v-app>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify"

const mainStore = useMainStore()
const authStore = useAuthStore()
const { snackbars } = storeToRefs(mainStore)
const { decodedTokenClaims } = storeToRefs(authStore)

const { themes, global: globalTheme } = useTheme()

const { theme, cookiePreferences } = useUserPreferences()

const head = useLocaleHead()

const setCookiePreferences = (preferences: Array<string>) => {
  cookiePreferences.value = preferences
}

watch(
  theme,
  () => {
    if (!theme.value) {
      const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches

      globalTheme.name.value = prefersDark ? "dark" : decodedTokenClaims.value?.domain === DomainType.CIVIL ? "civilLight" : "light"
    }
    if (theme.value && themes.value[theme.value]) {
      globalTheme.name.value = theme.value
    }
  },
  { immediate: true },
)
</script>
